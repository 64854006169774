<template>
  <div id="front-layout" class="">
      <main>
        <div>
          <router-view></router-view>
        </div>
      </main>
  </div>
</template>

<script>
export default {
  name: 'front-layout',
  components: {
    //   
  },
  data () {
    return {
      // 
    }
  },
  created (){ 
  },
  mounted () {
    // 
  },
  updated () {
    // 
  }
}
</script>

<style scoped>
  body{
    background-color: #fff  !important;
  }
</style>
